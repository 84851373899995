import {ErrorCodes} from '~/services/error'
import {formatError} from '~/utils/formatters'
import {getUseSharedReducer} from '~/utils/getUseSharedReducer'

import type {BackendError} from '~/services/error'

const DEFAULT_TIMEOUT = 5000
let popupId = 0

export type PopupData = {
    id: number
    message: string | JSX.Element
    type: 'error' | 'message'
    timeoutId: number
    multiline: boolean
    close: () => void
    clearTimeout: () => void
}

type PopupAction =
    | {
        type: 'add'
        payload: PopupData
    }
    | {
        type: 'remove'
        payload: number
    }
    | {type: 'removeAll'}

function reducer(state: PopupData[], action: PopupAction) {
    switch (action.type) {
    case 'add':
        return [
            ...state,
            action.payload,
        ]

    case 'remove':
        return state.filter(({id}) => id != action.payload)

    case 'removeAll':
        return []
    }
}

export const usePopups = getUseSharedReducer(reducer, [])

export function showPopup(message: string | JSX.Element, options?: {type?: PopupData['type'], timeout?: number, multiline?: boolean}) {
    const timeout = Number.isFinite(options?.timeout) ? options?.timeout : DEFAULT_TIMEOUT
    const id = popupId++
    const timeoutId = timeout
        ? window.setTimeout(() => hide(id), timeout)
        : 0

    const popupData = {
        id,
        message,
        type: options?.type || 'message',
        timeoutId,
        multiline: !!options?.multiline,
        close: () => hide(id),
        clearTimeout: () => clearInterval(timeoutId),
    }

    usePopups.dispatch({type: 'add', payload: popupData})
}

export function showErrorPopup(error: BackendError): never {
    if (error.code != ErrorCodes.userCancel)
        showPopup(formatError(error), {type: 'error'})
    throw error
}

function hide(id: number) {
    usePopups.dispatch({type: 'remove', payload: id})
}
